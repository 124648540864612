import config from "constants/api";
import request from "constants/requests";
import routes from "routing/routes";
import { toast } from "react-toastify";
import { handlingErrors } from "utils/helpers";
import { createSlice, Dispatch } from "@reduxjs/toolkit";

export interface RegisterValuesTypes {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
    businessName: string;
    businessType?: string;
    merchantType?: string;
    contractCode?: string;
    secretKey?: string;
    apiKey?: string;
}

export interface VerificationValuesTypes {
    activationCode: string;
}

export interface LoginValuesTypes {
    email: string;
    password: string;
}

const initialState = {
    token: { refreshToken: null },
    kyc: { loading: false, error: {}, response: {} },
    login: { loading: false, error: {}, response: {} },
    logout: { loading: false, error: {}, response: {} },
    register: { loading: false, error: {}, response: {} },
    verification: { loading: false, error: {}, response: {} },
    forgotPassword: { loading: false, error: {}, response: {} },
    changePassword: { loading: false, error: {}, response: {} },
    resendVerification: { loading: false, error: {}, response: {} },
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setRefreshToken: (state, { payload }) => {
            state.token.refreshToken = payload;
        },
        login: (state) => {
            let { login } = state;

            login.loading = true;
            login.error = initialState.login.error;
            login.response = initialState.login.response;
        },
        loginSuccess: (state, { payload }) => {
            let { login } = state;

            login.loading = false;
            login.response = payload;
            login.error = initialState.login.error;
        },
        loginFailed: (state, { payload }) => {
            let { login } = state;

            login.loading = false;
            login.response = initialState.login.response;
            login.error = payload;
        },
        register: (state) => {
            let { register } = state;
            register.loading = true;
            register.response = initialState.register.response;
            register.error = initialState.register.error;
        },
        registerSuccess: (state, { payload }) => {
            let { register } = state;
            register.loading = false;
            register.response = payload;
            register.error = initialState.register.error;
        },
        registerFailed: (state, { payload }) => {
            let { register } = state;
            register.loading = false;
            register.response = initialState.register.response;
            register.error = payload;
        },
        verification: (state) => {
            let { verification } = state;
            verification.loading = true;
            verification.response = initialState.verification.response;
            verification.error = initialState.verification.error;
        },
        verificationSuccess: (state, { payload }) => {
            let { verification } = state;
            verification.loading = false;
            verification.response = payload;
            verification.error = initialState.verification.error;
        },
        verificationFailed: (state, { payload }) => {
            let { verification } = state;
            verification.loading = false;
            verification.response = initialState.verification.response;
            verification.error = payload;
        },
        resendVerification: (state) => {
            let { resendVerification } = state;
            resendVerification.loading = true;
            resendVerification.response = initialState.resendVerification.response;
            resendVerification.error = initialState.resendVerification.error;
        },
        resendVerificationSuccess: (state, { payload }) => {
            let { resendVerification } = state;
            resendVerification.loading = false;
            resendVerification.response = payload;
            resendVerification.error = initialState.resendVerification.error;
        },
        resendVerificationFailed: (state, { payload }) => {
            let { resendVerification } = state;
            resendVerification.loading = false;
            resendVerification.response = initialState.resendVerification.response;
            resendVerification.error = payload;
        },
        logout: (state) => {
            let { logout } = state;
            logout.loading = true;
            logout.response = initialState.logout.response;
            logout.error = initialState.logout.error;
        },
        logoutSuccess: (state, { payload }) => {
            let { logout } = state;
            logout.loading = false;
            logout.response = payload;
            logout.error = initialState.logout.error;
        },
        logoutFailed: (state, { payload }) => {
            let { logout } = state;
            logout.loading = false;
            logout.response = initialState.logout.response;
            logout.error = payload;
        },
        updateKyc: (state) => {
            let { kyc } = state;
            kyc.loading = true;
            kyc.response = initialState.kyc.response;
            kyc.error = initialState.kyc.error;
        },
        updateKycSuccess: (state, { payload }) => {
            let { kyc } = state;
            kyc.loading = false;
            kyc.response = payload;
            kyc.error = initialState.kyc.error;
        },
        updateKycFailed: (state, { payload }) => {
            let { kyc } = state;
            kyc.loading = false;
            kyc.response = initialState.kyc.response;
            kyc.error = payload;
        },
        forgotPassword: (state) => {
            let { forgotPassword } = state;
            forgotPassword.loading = true;
            forgotPassword.response = initialState.forgotPassword.response;
            forgotPassword.error = initialState.forgotPassword.error;
        },
        forgotPasswordSuccess: (state, { payload }) => {
            let { forgotPassword } = state;
            forgotPassword.loading = false;
            forgotPassword.response = payload;
            forgotPassword.error = initialState.forgotPassword.error;
        },
        forgotPasswordFailed: (state, { payload }) => {
            let { forgotPassword } = state;
            forgotPassword.loading = false;
            forgotPassword.response = initialState.forgotPassword.response;
            forgotPassword.error = payload;
        },
        changePassword: (state) => {
            let { changePassword } = state;
            changePassword.loading = true;
            changePassword.response = initialState.changePassword.response;
            changePassword.error = initialState.changePassword.error;
        },
        changePasswordSuccess: (state, { payload }) => {
            let { changePassword } = state;
            changePassword.loading = false;
            changePassword.response = payload;
            changePassword.error = initialState.changePassword.error;
        },
        changePasswordFailed: (state, { payload }) => {
            let { changePassword } = state;
            changePassword.loading = false;
            changePassword.response = initialState.changePassword.response;
            changePassword.error = payload;
        },
    },
});

export const {
    setRefreshToken,
    login,
    loginSuccess,
    loginFailed,
    register,
    registerSuccess,
    registerFailed,
    verification,
    verificationSuccess,
    verificationFailed,
    resendVerification,
    resendVerificationSuccess,
    resendVerificationFailed,
    updateKyc,
    updateKycSuccess,
    updateKycFailed,
    forgotPassword,
    forgotPasswordSuccess,
    forgotPasswordFailed,
    changePassword,
    changePasswordSuccess,
    changePasswordFailed,
} = authSlice.actions;

export const forgotPasswordFn = (values: { email: string }, cb?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(forgotPassword());
        const response = await request({
            method: "post",
            url: "/auth/password/forget",
            data: values,
        });
        console.log(response);

        dispatch(forgotPasswordSuccess(response?.data));

        toast.success(response?.data?.message);
        if (cb) {
            cb();
        }

        console.log(response);
    } catch (error) {
        dispatch(forgotPasswordFailed(error?.response?.data || error?.response));
    }
};

export const changePasswordFn = (values: { resetCode: string; password: string }, cb?: () => void) => async (dispatch: Dispatch) => {
    try {
        dispatch(changePassword());
        const response = await request({
            method: "post",
            url: "/auth/password/reset",
            data: { ...values, password: btoa(values?.password) },
        });
        console.log(response);

        dispatch(changePasswordSuccess(response?.data));
        toast.success(response?.data?.message);

        if (cb) {
            cb();
        }

        console.log(response);
    } catch (error) {
        dispatch(changePasswordFailed(error?.response?.data || error?.response));
    }
};

export const loginUserFn = (values: LoginValuesTypes, cb?: (role: string) => void) => async (dispatch: Dispatch) => {
    dispatch(login());
    try {
        const { data, headers } = await request({
            method: "post",
            url: "/auth/login",
            data: { email: btoa(values?.email), password: btoa(values?.password) },
        });

        if (data && data?.data) {
            dispatch(loginSuccess(data));

            localStorage.setItem(config.ACCESS_TOKEN, headers["x-access-token"]);
            localStorage.setItem(config.REFRESH_TOKEN, headers["x-refresh-token"]);

            switch (data?.data?.role) {
                case "USER":
                    localStorage.setItem(config.USER, JSON.stringify(data?.data));
                    localStorage.setItem(config.ACCOUNT_MODE, data?.merchant?.mode);
                    localStorage.setItem(config.MERCHANT, JSON.stringify(data?.merchant));

                    if (cb) {
                        cb(data?.data?.role);
                    }
                    break;
                case "PARTNER":
                case "ADMINISTRATOR":
                    localStorage.setItem(config.ACCOUNT_MODE, "PRODUCTION");
                    localStorage.setItem(config.USER, JSON.stringify(data?.data));
                    if (cb) {
                        cb(data?.data?.role);
                    }
            }
        }
    } catch (error) {
        handlingErrors(error, dispatch, loginFailed);
    }
};

export const logoutFn = () => async () => {
    console.log("about to logout...");
    try {
        await request({ method: "post", url: "/auth/logout" });
    } catch (error) {
        console.log("There is a problem logging out");
    }

    localStorage.removeItem(config.USER);
    localStorage.removeItem(config.ACCESS_TOKEN);
    localStorage.removeItem(config.REFRESH_TOKEN);
    localStorage.removeItem(config.ACCOUNT_MODE);

    window.location.reload();
};

export const registerUserFn = (values: RegisterValuesTypes) => async (dispatch: Dispatch) => {
    try {
        dispatch(register());

        const response = await request({
            method: "post",
            url: "/merchant",
            data: { ...values, password: btoa(values?.password), merchantType: "PLATINUM" },
        });
        dispatch(registerSuccess(response?.data));
        toast.success(response?.data?.message);
        setTimeout(() => {
            if (response?.data?.requireVerification) {
                window.location.assign(`${routes.VERIFICATION}/${values?.email}`);
            } else {
                window.location.assign(`${routes.LOGIN}`);
            }
        }, 5000);
    } catch (error) {
        dispatch(registerFailed(error?.response?.data || error?.response));
    }
};

export const verifyUserFn =
    (values: VerificationValuesTypes, cb: { (id: string): any; (id: string): void }) => async (dispatch: Dispatch) => {
        try {
            dispatch(verification());
            const response = await request({
                method: "put",
                url: "/merchant/verify",
                data: values,
            });

            dispatch(verificationSuccess(response?.data));
            toast.success(response?.data?.message);
            cb(response?.data?.id);
        } catch (error) {
            if (error?.response) {
                dispatch(verificationFailed(error?.response?.data || error?.response));
                toast.error(error?.response?.data || error?.response);
            } else {
                dispatch(verificationFailed(error?.message));
                toast.error(error?.message);
            }
        }
    };

export const resendVerificationFn = (email: string) => async (dispatch: Dispatch) => {
    try {
        dispatch(verification());
        const response = await request({
            method: "post",
            data: { email },
            url: "/merchant/verify/resend",
        });

        dispatch(verificationSuccess(response?.data));

        toast.success(response?.data?.message || "Code sent. Check your email");
    } catch (error) {
        dispatch(verificationFailed(error?.response?.data || error?.response));
    }
};

export const updateKycFn =
    ({ cb, bvn, image, imageTitle }: Record<string, any>) =>
    async (dispatch: Dispatch) => {
        try {
            dispatch(updateKyc());

            const formData = new FormData();
            formData.append("directorsBVN", bvn);
            formData.append("cac_pack", image, imageTitle);

            const response = await request({
                method: "put",
                data: formData,
                url: "/merchant/complete-merchant-registration",
                headers: { "Content-Type": "multipart/form-data" },
            });

            dispatch(updateKycSuccess(response?.data));

            cb();
        } catch (error) {
            dispatch(updateKycFailed(error?.response?.data || error?.response));
        }
    };

export const selectAuthState = (state: any) => state.auth;

export default authSlice.reducer;
