import axios from "axios";
import config from "./api";
import NProgress from "nprogress";
import { toast } from "react-toastify";
import { logoutFn } from "slices/authSlice";

import { dispatch } from "../App";

interface Request {
  method: "get" | "post" | "put" | "delete" | "patch";
  url?: string;
  data?: object;
  params?: object;
  headers?: object;
}

const request = ({ method, url, params, data, headers }: Request) => {
  const customHeader = () => {
    if (localStorage.getItem(config.ACCESS_TOKEN)) {
      return {
        "X-Access-Token": localStorage.getItem(config.ACCESS_TOKEN),
        "X-Refresh-Token": localStorage.getItem(config.REFRESH_TOKEN),
      };
    }
  };

  NProgress.start();

  return axios({
    url,
    method,
    baseURL: config.API_URL,
    headers: {
      ...customHeader(),
      ...headers,
    },
    data,
    params,
  })
    .then((response) => {
      // if (response.headers["x-access-token"]) {
      //     localStorage.setItem(config.ACCESS_TOKEN, response.headers["x-access-token"]);
      // }

      // if (response.headers["x-refresh-token"]) {
      //     localStorage.setItem(config.REFRESH_TOKEN, response.headers["x-refresh-token"]);
      // }

      return response;
    })
    .catch(async (error) => {
      if (error?.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error?.response?.data);
        console.log(error?.response?.status);
        console.log(error?.response?.headers);

        if (error?.response?.status === 401) {
          dispatch(logoutFn());
        } else {
          toast.error(
            error?.response?.data?.message ||
              "Something went wrong, try again later"
          );
        }
      } else if (error?.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error?.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error?.message);
      }
      // console.log(error.config);

      throw error;
    })
    .finally(() => {
      NProgress.done();
    });
};

export default request;
