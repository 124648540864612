import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./Cards.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector } from "react-redux";
import { selectTransactionAccessModeState } from "slices/transactionsSlice";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import SideContent from "layouts/Dashboard/components/SideContent/SideContent";
import ModalBackground from "layouts/Dashboard/components/ModalBackground/ModalBackground";
import request from "constants/requests";
import SearchIcon from "assets/images/search-icon.svg";
import { UploadBatchCards } from "./components/UploadBatchCard";
import { FilterBatchCards } from "./components/FilterCards/FilterCards";

interface ICardDetails {
  id: string;
  batchNumber: string;
  requestStatus: string;
  initiatedAt: string;
}

interface ICardResponse {
  status: boolean;
  responseCode: string;
  size: number;
  page: number;
  totalCount: number;
  totalPages: number;
  data: ICardDetails[];
}

const STATUS_OBJ = {
  REQUESTED: { label: "Requested", bgColor: "#FBB900" },
  PROCESSED: { label: "Processed", bgColor: "green" },
  DECLINED: { label: "Declined", bgColor: "red" },
};

const CardsScreen: React.FC = () => {
  const history = useHistory();
  const mode = useSelector(selectTransactionAccessModeState);

  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sideContent, setSideContent] = useState("");
  const [cards, setCards] = useState<ICardDetails[]>([]);
  const [loading, setLoading] = useState("loading");
  const [metaData, setMetaData] = useState({ totalRecords: 0, totalPage: 1 });
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [error, setError] = useState("");

  const renderRows = () => {
    return cards.map((each) => {
      return cards?.length ? (
        <tr key={each?.id}>
          <td style={{ display: "flex", alignItems: "center", width: "35%" }}>
            {each?.batchNumber}
          </td>
          <td className={styles.descriptionRow} style={{ width: "30%" }}>
            {format(new Date(each?.initiatedAt), "dd-MM-yyyy p")}
          </td>
          <td
            style={{
              width: "20%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  display: "inline-block",
                  backgroundColor: STATUS_OBJ[each?.requestStatus]?.bgColor,
                }}
              />
              <p style={{ flex: 1, margin: 0 }}>
                {STATUS_OBJ[each?.requestStatus]?.label}
              </p>
            </div>
          </td>
          <td style={{ display: "flex", alignItems: "center", width: "15%" }}>
            {each?.requestStatus === "PROCESSED" && (
              <div
                style={{
                  cursor: "pointer",
                  color: "green",
                  textTransform: "uppercase",
                  fontSize: "13px",
                  fontWeight: 600,
                  textDecorationLine: "underline",
                }}
                onClick={() => {
                  if (each?.requestStatus === "PROCESSED") {
                    history.push(`/cards/${each?.batchNumber}`);
                  }
                }}
              >
                View
              </div>
            )}
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan={5} className="text-center">
            No Batch Card Available
          </td>
        </tr>
      );
    });
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data.selected;
    const currentPage = selected + 1;

    setCurrentPage(currentPage);
  };

  const openModal = (content: string) => {
    setSideContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSideContent("");
  };

  const getBatchCards = async () => {
    setError("");

    const params: any = { page: currentPage, size: 50 };

    if (filter?.search) {
      params.batchNumber = filter?.search;
    }
    if (filter?.order) {
      params.sortOrder = filter?.order;
    }
    if (filter?.status) {
      params.batchStatus = filter?.status;
    }

    try {
      const { data }: { data: ICardResponse } = await request({
        method: "get",
        url: "/fip/list",
        params,
      });
      setCards(data?.data);
      setMetaData({
        totalRecords: data?.totalCount,
        totalPage: data?.totalPages,
      });
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
    } finally {
      setLoading("");
    }
  };

  useEffect(() => {
    if (!loading) {
      setLoading("fetching");
    }
    getBatchCards();
  }, [filter?.search, filter?.order, filter?.status, currentPage]);

  useEffect(() => {
    if (!search && filter?.search) {
      setFilter({ ...filter, search });
    }
  }, [search]);

  const renderSideContent = () => {
    if (sideContent === "upload card") {
      return (
        <UploadBatchCards
          closeModal={closeModal}
          refetch={() => {
            setLoading("fetching");
            getBatchCards();
          }}
        />
      );
    } else if (sideContent === "filter") {
      return (
        <FilterBatchCards
          closeModal={closeModal}
          handleFilter={(val) => {
            setFilter({ ...filter, ...val });
            closeModal();
          }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ModalBackground modalIsOpen={modalIsOpen} closeModalFn={closeModal} />
      <SideContent modalIsOpen={modalIsOpen} closeModalFn={closeModal}>
        {renderSideContent()}
      </SideContent>
      <DashboardLayout>
        {loading === "loading" ? (
          <Loading isSpaced />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Batch Cards</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Batch Cards
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {metaData?.totalRecords || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              <div className={styles.create__card__container}>
                <form
                  className={styles.search__container}
                  onSubmit={(e) => {
                    e.preventDefault();
                    setFilter({ ...filter, search });
                  }}
                >
                  <img
                    src={SearchIcon}
                    alt=""
                    className={styles.search__icon}
                  />
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={styles.input}
                    placeholder="Search by batch number"
                    disabled={false}
                  />
                </form>
                <div className={styles.create__card__wrapper}>
                  <button
                    className={styles.card__filter}
                    onClick={() => openModal("filter")}
                  >
                    Filter
                  </button>
                  <div
                    className={styles.card__btn}
                    onClick={() => openModal("upload card")}
                  >
                    Upload Batch Card
                  </div>
                </div>
              </div>
              <Table
                headers={["batch no", "date", "status", "action"]}
                widths={["35%", "30%", "20%", "15%"]}
                noData={cards?.length === 0 && !loading && !error}
              >
                {loading === "fetching" ? (
                  <tr className="bg-white">
                    <td colSpan={5} className="text-center">
                      <Loading isSpaced />
                    </td>
                  </tr>
                ) : error ? (
                  <tr className="bg-white">
                    <td colSpan={4} className="text-center">
                      <div
                        style={{
                          padding: "50px 20px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          rowGap: "5px",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          Unable to get batch card
                        </p>
                        <button
                          style={{
                            padding: "10px 30px",
                            borderRadius: "5px",
                            outline: "none",
                            border: 0,
                            backgroundColor: "var(--primary-color)",
                            color: "white",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setLoading("fetching");
                            getBatchCards();
                          }}
                        >
                          Try Again
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              <nav aria-label="navigation">
                {metaData?.totalPage > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    forcePage={currentPage - 1}
                    pageCount={metaData?.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
      {/* <div
        className={styles.filter__overlay}
        style={{ display: toggleFilter ? "block" : "none" }}
        onClick={() => setToggleFilter(false)}
      /> */}
    </>
  );
};

export default CardsScreen;
