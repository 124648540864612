import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import config from "constants/api";
import LogoImg from "assets/images/new-logo.png";
import ProvidusAvatar from "assets/images/tagpay-avatar.png";
import routes from "routing/routes";

import styles from "./Navbar.module.css";

import { setAccessMode, selectBankState } from "slices/bankSlice";

const Navbar: React.FC = () => {
  const dispatch = useDispatch();

  const { accessMode } = useSelector(selectBankState);

  const user = JSON.parse(localStorage.getItem("user") || "");

  return (
    <div className={styles.navbar}>
      <div className={`${styles.navbar__content} container`}>
        <div>
          <Link to={routes.BANK_HOME}>
            <img className={styles.navbar__logo} src={LogoImg} alt="Home" />
          </Link>
        </div>
        <div className="custom-control custom-switch">
          <input
            id="testMode"
            type="checkbox"
            className="custom-control-input"
            checked={accessMode === "PRODUCTION" ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                dispatch(setAccessMode("PRODUCTION"));
                localStorage.setItem(config.ACCOUNT_MODE, "PRODUCTION");
              } else {
                dispatch(setAccessMode("SANDBOX"));
                localStorage.setItem(config.ACCOUNT_MODE, "SANDBOX");
              }
            }}
          />
          <label className="custom-control-label" htmlFor="testMode">
            {accessMode === "PRODUCTION" ? "Live Mode On" : "Test Mode On"}
          </label>
        </div>
        <div className="d-flex flex-row align-items-center">
          <img
            src={ProvidusAvatar}
            alt="Tagpay Avatar"
            className="rounded-circle mr-2"
          />
          <div className={styles.navbar__rightContent}>
            <span className={styles.navbar__username}>
              {user.lastName} {user.firstName}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
