import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DashboardLayout from "layouts/Dashboard/Dashboard";
import styles from "./CardDetails.module.css";
import Header from "components/Header/Header";
import Table from "components/Table/Table";
import { useSelector } from "react-redux";
import { selectTransactionAccessModeState } from "slices/transactionsSlice";
import Loading from "components/Loading/Loading";
import ReactPaginate from "react-paginate";
import request from "constants/requests";

interface ICardDetails {
  id: string;
  batchNumber: string;
  cardholder: string;
  maskedPan: string;
  accountNumber: string;
  requiresRequery: boolean;
  issueDate: string;
  status: string;
  expiry: string;
  providerCardId: string;
}

interface ICardResponse {
  status: boolean;
  // perPage: number;
  // currentPage: number;
  // totalRecords: number;
  // totalPages: number;
  data: { cards: ICardDetails[] };
}

const STATUS_OBJ = {
  ACTIVE: { label: "Active", bgColor: "#FBB900" },
  INACTIVE: { label: "Inactive", bgColor: "red" },
};

const perPage = 50;

const CardDetailsScreen: React.FC = () => {
  const { id } = useParams() as Record<string, string | undefined>;
  const mode = useSelector(selectTransactionAccessModeState);

  const [currentPage, setCurrentPage] = useState(1);
  const [cards, setCards] = useState<ICardDetails[]>([]);
  const [loading, setLoading] = useState("loading");
  const [metaData, setMetaData] = useState({ totalRecords: 0, totalPage: 1 });
  const [requerying, setRequerying] = useState("");
  const [error, setError] = useState("");
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setCurrentItems(cards?.slice(itemOffset, endOffset));
  }, [itemOffset, cards]);

  const renderRows = () => {
    return currentItems?.map((each, index: number) => {
      return currentItems?.length ? (
        <tr key={index}>
          <td
            style={{
              display: "flex",
              alignItems: "center",
              width: "17%",
            }}
          >
            {each?.maskedPan || "-"}
          </td>
          <td style={{ width: "20%" }}>{each?.cardholder}</td>
          <td className={styles.descriptionRow} style={{ width: "10%" }}>
            {each?.expiry
              ? `${each?.expiry?.slice(2)}/${each?.expiry?.slice(0, 2)}`
              : "-"}
          </td>
          <td style={{ width: "15%" }}>{each?.accountNumber || "-"}</td>
          <td style={{ width: "13%" }}>
            {each?.issueDate
              ? format(new Date(each?.issueDate), "dd-MM-yyyy")
              : "-"}
          </td>
          <td style={{ width: "12%" }}>
            {each?.status ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                <span
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "inline-block",
                    backgroundColor: STATUS_OBJ[each?.status]?.bgColor,
                  }}
                />
                <p style={{ flex: 1, margin: 0 }}>
                  {STATUS_OBJ[each?.status]?.label}
                </p>
              </div>
            ) : (
              "-"
            )}
          </td>
          <td style={{ width: "13%" }}>
            {each?.requiresRequery ? (
              <button
                style={{
                  padding: "5px 10px",
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  outline: "none",
                  background: "white",
                  border: "1px solid var(--primary-color)",
                  borderRadius: "5px",
                }}
                onClick={() => requeryCardInormation(each?.accountNumber)}
                disabled={requerying === each?.accountNumber ? true : false}
              >
                {requerying === each?.accountNumber
                  ? "Requerying..."
                  : "Requery"}
              </button>
            ) : null}
          </td>
        </tr>
      ) : (
        <tr>
          <td colSpan={5} className="text-center">
            No Card Available
          </td>
        </tr>
      );
    });
  };

  const handlePageClick = (data: { selected: number }) => {
    let selected = data?.selected;
    const newOffset = (selected * perPage) % cards.length;

    setItemOffset(newOffset);
    setCurrentPage(selected + 1);
  };

  const getCardDetails = async () => {
    setError("");

    const params = { currentPage, perPage };

    try {
      const { data }: { data: ICardResponse } = await request({
        method: "get",
        url: `/fip/batch/${id}`,
        params,
      });
      setCards(data?.data?.cards);
      setMetaData({
        totalRecords: 0,
        totalPage: 1,
      });
    } catch (err) {
      setError(err?.response?.data?.message || err?.message);
    } finally {
      setLoading("");
    }
  };

  const requeryCardInormation = async (accountNumber: string) => {
    setRequerying(accountNumber);

    try {
      await request({ method: "get", url: `/fip/card/${accountNumber}` });
      setLoading("fetching");
      getCardDetails();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setRequerying("");
    }
  };

  useEffect(() => {
    if (id) {
      getCardDetails();
    }
  }, [id, currentPage]);

  return (
    <>
      <DashboardLayout>
        {loading === "loading" ? (
          <Loading isSpaced />
        ) : (
          <div className={styles.transactions}>
            <div className="mb-4 d-flex justify-content-between">
              <Header>Cards</Header>
              <div>
                <p className={styles.transactions__totalText}>
                  Total Numbers of Cards
                </p>
                <h4 className={styles.transactions__totalAmount}>
                  {cards?.length || 0}
                </h4>
              </div>
            </div>

            <div className={styles.transactions__content}>
              <Table
                headers={[
                  "Pan",
                  "card holder",
                  "expiry",
                  "account number",
                  "issue date",
                  "status",
                  "Action",
                ]}
                widths={["17%", "20%", "10%", "15%", "13%", "12%", "13%"]}
                noData={
                  cards?.length === 0 && !loading && !error ? true : false
                }
              >
                {loading === "fetching" ? (
                  <tr className="bg-white">
                    <td colSpan={7} className="text-center">
                      <Loading isSpaced />
                    </td>
                  </tr>
                ) : error ? (
                  <tr className="bg-white">
                    <td colSpan={4} className="text-center">
                      <div
                        style={{
                          padding: "50px 20px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          rowGap: "5px",
                          width: "100%",
                        }}
                      >
                        <p
                          style={{
                            color: "black",
                            fontSize: "18px",
                            textAlign: "center",
                          }}
                        >
                          Unable to get card records
                        </p>
                        <button
                          style={{
                            padding: "10px 30px",
                            borderRadius: "5px",
                            outline: "none",
                            border: 0,
                            backgroundColor: "var(--primary-color)",
                            color: "white",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setLoading("fetching");
                            getCardDetails();
                          }}
                        >
                          Try Again
                        </button>
                      </div>
                    </td>
                  </tr>
                ) : (
                  renderRows()
                )}
              </Table>

              <nav aria-label="navigation">
                {metaData?.totalPage > 1 && (
                  <ReactPaginate
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    forcePage={currentPage - 1}
                    pageCount={metaData?.totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                  />
                )}
              </nav>
            </div>
          </div>
        )}
      </DashboardLayout>
      {/* <div
        className={styles.filter__overlay}
        style={{ display: toggleFilter ? "block" : "none" }}
        onClick={() => setToggleFilter(false)}
      /> */}
    </>
  );
};

export default CardDetailsScreen;
